export const styles = {
    backgrounds: [
        { className: "primary-bg-colour", colour: "#E60000" },
        { className: "secondary-bg-colour", colour: "#27272A" },
        { className: "tertiary-bg-colour", colour: "#1C1C1C" },
        { className: "inactive-bg-colour", colour: "#141414" },
        { className: "misc-bg-colour", colour: "#4444441f" },
    ],
    text: [
        { className: "primary-text", colour: "#ffffff" },
        { className: "secondary-text", colour: "#191919" },
        { className: "tertiary-text", colour: "#ffffff" },
        { className: "inactive-text", colour: "#B6B6B6" },
        { className: "misc-text", colour: "#E60000" },
    ],
    borders: [
        { className: "primary-border-colour", colour: "#E60000" },
        { className: "secondary-border-colour", colour: "#191919" },
        { className: "primary-free-border-colour", colour: "#44444440" },
    ],
};

