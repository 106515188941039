import store from "@/store";
import isMobile from "@/functions/deviceDetection";
const CryptoJS = require("crypto-js");

function addDaysToDateTime(date, n) {
    const d = new Date(date);
    d.setDate(d.getDate() + n);
    return d.toLocaleDateString();
}

export function utagDataPost(to, from, next) {
    const utagData = {
        tealium_event: "view",
        page_name: `rugby-zone:${to.path.substring(1, to.path.length)}`,
        page_section: `rugby-zone:${to.path.substring(1, to.path.length)}`,
        page_parent_domain: window.origin,
        page_country: "south africa",
        page_url: `${window.origin}${to.fullPath}`,
        page_locale: "za",
        page_title: document.title,
        page_platform: isMobile() ? 'web-mobile' : 'web-desktop',
        visitor_id_asset_active: store.state.user && isUserLoggedIn(to) ? encryptNumber(store.state.user.msisdn) : '',
        visitor_login_status: store.state.user && isUserLoggedIn(to) ? `loggedin` : `loggedout`,
        site_version: "1.0",
        site_type: "web",
    };

    if (from.name === 'ConfirmSubscription' && to.name === 'HomeView') {
        Object.assign(utagData, {
            event_name: "subscription",
            product_id: [`${store.state.user.serviceId}`],
            product_name: ["Rugby Zone"],
            product_price: getPricePoint(),
            product_category: ["Subscription"],
            product_sku: ["12345"],
            product_type: "Mobile",
            visitor_asset_plan_active: ["Rugby Zone"],
            visitor_asset_plan_id_active: `${store.state.user?.subscriptionId}`,
            visitor_asset_subscription_id_active: `${store.state.user?.subscriptionId}`,
            visitor_asset_plan_name_active: ["Rugby Zone"],
            visitor_asset_plan_type_active: "Subscription",
            visitor_asset_plan_brand_active: "games",
            visitor_asset_duration_active: getActiveDuration(),
            visitor_assest_type_active: "free trial",
            visitor_assest_date_end_active: getEndDateOfActiveSub(),
            visitor_assest_date_start_active: new Date().toLocaleDateString(),
            visitor_addon_name_active: getActiveDuration(),
            visitor_addon_type_active: "free trial",
            visitor_addon_date_end_active: getEndDateOfActiveSub(),
            visitor_addon_date_start_active: new Date().toLocaleDateString(),
            subscription_completed: 1,
            transaction_id: `${store.state.user?.subscriptionId}`,
            order_revenue: getPricePoint(),
            order_completed: 1,
            subscription_type: getSubscriptionType(),
        })
    }

    if (to.name === 'HomeView' && (from.path === '/request-otp' || from.path === '/submit-otp')) Object.assign(utagData, {login_successful: 1})


    if (utag) {
        console.log(utagData)
        utag.view(utagData);
    }
}

function isUserLoggedIn(to) {
    if (to.name === 'RequestOtp') return false;
    if (to.name === 'SubmitOtp') return false;
    if (to.name === 'WelcomeView') return false;
    else return true;
}

function getPricePoint() {
    if (store.state.user.serviceId === "vc-fanclash-vodacom-fl-rugby-daily-01") return "5.00"
    if (store.state.user.serviceId === "vc-fanclash-vodacom-fl-rugby-weekly-01") return "20.00"
    if (store.state.user.serviceId === "vc-fanclash-vodacom-fl-rugby-monthly-01") return "35.00"
}

function getActiveDuration() {
    if (store.state.user.serviceId === "vc-fanclash-vodacom-fl-rugby-daily-01") return "1 Days"
    if (store.state.user.serviceId === "vc-fanclash-vodacom-fl-rugby-weekly-01") return "7 Days"
    if (store.state.user.serviceId === "vc-fanclash-vodacom-fl-rugby-monthly-01") return "30 Days"
}

function getSubscriptionType() {
    if (store.state.user.serviceId === "vc-fanclash-vodacom-fl-rugby-daily-01") return "daily"
    if (store.state.user.serviceId === "vc-fanclash-vodacom-fl-rugby-weekly-01") return "weekly"
    if (store.state.user.serviceId === "vc-fanclash-vodacom-fl-rugby-monthly-01") return "monthly"
}

function getEndDateOfActiveSub() {
    if (store.state.user.serviceId === "vc-fanclash-vodacom-fl-rugby-daily-01") return addDaysToDateTime(new Date().toLocaleDateString(), 1)
    if (store.state.user.serviceId === "vc-fanclash-vodacom-fl-rugby-weekly-01") return addDaysToDateTime(new Date().toLocaleDateString(), 7)
    if (store.state.user.serviceId === "vc-fanclash-vodacom-fl-rugby-monthly-01") return addDaysToDateTime(new Date().toLocaleDateString(), 30)
}

function encryptNumber(number) {
    const key = CryptoJS.enc.Utf8.parse('1234567890123456'); // 16-byte key for AES-128
    const iv = CryptoJS.enc.Utf8.parse('1234567890123456'); // 16-byte IV

    const encrypted = CryptoJS.AES.encrypt(number.toString(), key, { iv: iv });
    const encryptedString = encrypted.toString();

    // Shorten the encrypted string to a fixed length, if needed
    const shortEncryptedString = encryptedString.slice(0, 16);

    return shortEncryptedString;
}
