export default class Helpers {
    static findCarImageModule(team) {
        const formattedTeamName = this.formatTextForAssetNames(team);
        try {
            return require(`../assets/jerseys/scrum-cap.png`);
        } catch (e) {
            return require(`../assets/jerseys/default-scrum-cap.png`);
        }
    }

    static findJerseyImageModule(name) {
        const hasName = name !== 'no-player'
        const formattedTeamName = this.formatTextForAssetNames(name);
        console.log(formattedTeamName)
        if (hasName) {
            try {
                return require(`../assets/jerseys/${formattedTeamName}-kit.webp`);
            } catch (e) {
                return require(`../assets/scrumCaps/btn-add-player.png`);
            }
        } else return require(`../assets/scrumCaps/btn-add-player.png`);
    }

    static findLeagueImageModule(league) {
        const name = typeof league === "object" ? league.leagueName : league
        const reformattedLeagueName = this.formatTextForAssetNames(name);
        try {
            return require(`../assets/leagueLogos/${reformattedLeagueName}-logo-colour.webp`)
        } catch (e) {
            return require(`../assets/default-badge.webp`)
        }
    }

    static findTeamImageModule(teamName) {
        const reformattedTeamName = this.formatTextForAssetNames(teamName);
        console.log(reformattedTeamName)
        try {
            return require(`../assets/teamBadges/${reformattedTeamName}-logo-icon.webp`)
        } catch (e) {
            return require(`../assets/default-badge.webp`)
        }
    }

    static formatTextForAssetNames(text) {
        const lowerCase = text.toLowerCase();
        return lowerCase.replaceAll(' ', '-');
    }
}
