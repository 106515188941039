import {createStore} from 'vuex'

export default createStore({
    state: {
        msisdn: undefined,
        serviceId: undefined,
        brandConfig: undefined,
        upcomingFixtures: [],
        showAutoTeamBuilder: false,
        showHowToWinPoints: false,
        isLoading: undefined,
        roundId: undefined,
        leagueName: undefined,
        showSelectCaptain: false,
        showHint: false,
        showTriviaGameOver: false,
        showEditUserName: false,
        showEditAvatar: false,
        showInviteFriend: false,
        showSingleMatch: false,
        showSubUpsell: false,
        showNoQuestions: false,
        productId: 28,
        user: undefined,
        liveRoundsNotEntered: 0,
        roundsEntered: 0,
        predictedDrivers: undefined,
        predictedConstructors: undefined,
        selectedForwards: [],
        selectedBacks: [],
        selectedCaptain: [],
        showUnsubPopup: false,
        defferedPrompt: null,
        showAddToHomeScreen: false,
        feedArticles: [],
        showUpgradeToPremium: false
    },
    mutations: {
        setShowUpgradeToPremium(state, showUpgradeToPremium) {
            state.showUpgradeToPremium = showUpgradeToPremium
        },
        setFeedArticles(state, feedArticles) {
            state.feedArticles = feedArticles
        },
        setShowAddToHomeScreen(state, showAddToHomeScreen) {
            state.showAddToHomeScreen = showAddToHomeScreen
        },
        setDeferredPrompt(state, defferedPrompt) {
            state.defferedPrompt = defferedPrompt
        },
        setShowUnsubPopup(state, showUnsubPopup) {
            state.showUnsubPopup = showUnsubPopup
        },
        setSelectedCaptain(state, selectedCaptain) {
            state.selectedCaptain = selectedCaptain
        },
        setSelectedBacks(state, selectedBacks) {
            state.selectedBacks = selectedBacks
        },
        setSelectedForwards(state, selectedForwards) {
            state.selectedForwards = selectedForwards
        },
        setPredictedConstructors(state, predictedConstructors) {
            state.predictedConstructors = predictedConstructors
        },
        setPredictedDrivers(state, predictedDrivers) {
            state.predictedDrivers = predictedDrivers
        },
        setRoundsEntered(state, roundsEntered) {
            state.roundsEntered = roundsEntered
        },
        setLiveRoundsNotEntered(state, liveRoundsNotEntered) {
            state.liveRoundsNotEntered = liveRoundsNotEntered
        },
        setShowNoQuestions(state, showNoQuestions) {
            state.showNoQuestions = showNoQuestions
        },
        setShowSubUpsell(state, showSubUpsell) {
            state.showSubUpsell = showSubUpsell
        },
        setShowSingleMatch(state, showSingleMatch) {
            state.showSingleMatch = showSingleMatch
        },
        setServiceId(state, serviceId) {
            state.serviceId = serviceId
        },
        setMsisdn(state, msisdn) {
            state.msisdn = msisdn
        },
        setShowInviteFriend(state, showInviteFriend) {
            state.showInviteFriend = showInviteFriend
        },
        setShowEditAvatar(state, showEditAvatar) {
            state.showEditAvatar = showEditAvatar
        },
        setShowEditUserName(state, showEditUserName) {
            state.showEditUserName = showEditUserName
        },
        setShowTriviaGameOver(state, showTriviaGameOver) {
            state.showTriviaGameOver = showTriviaGameOver
        },
        setShowHint(state, showHint) {
            state.showHint = showHint
        },
        setShowSelectCaptain(state, showSelectCaptain) {
            state.showSelectCaptain = showSelectCaptain
        },
        setShowHowToWinPoints(state, showHowToWinPoints) {
            state.showHowToWinPoints = showHowToWinPoints
        },
        setShowAutoTeamBuilder(state, showAutoTeamBuilder) {
            state.showAutoTeamBuilder = showAutoTeamBuilder
        },
        setUpcomingFixtures(state, upcomingFixtures) {
            state.upcomingFixtures = upcomingFixtures
        },
        setBrandConfig(state, brandConfig) {
            state.brandConfig = brandConfig
        },
        setIsLoading(state, isLoading) {
            state.isLoading = isLoading
        },
        setUser(state, user) {
            state.user = user
        },
        setRoundId(state, roundId) {
            state.roundId = roundId
        },
        setLeagueName(state, leagueName) {
            state.leagueName = leagueName
        }
    },
})
